var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-between"},[_c('vs-breadcrumb',{attrs:{"items":[
          {
            title: 'Admin Tag Store',
            url: '/'
          },
          {
            title: 'Clientes',
            active: true
          }
        ]}})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col d-flex justify-content-between flex-wrap"},[_c('date-picker',{staticStyle:{"width":"220px"},attrs:{"range":""},on:{"change":function($event){return _vm.getDaterange()}},model:{value:(_vm.daterange),callback:function ($$v) {_vm.daterange=$$v},expression:"daterange"}}),_c('div',[_c('vs-input',{staticClass:"inputx",staticStyle:{"width":"400px"},attrs:{"placeholder":"Buscar por nombre/correo (Presione ENTER)"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getClients()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-card',[_c('vs-table',{staticClass:"mt-2",attrs:{"stripe":"","data":_vm.clients},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"text-left"},[_c('vs-td',[_vm._v(" "+_vm._s(tr.created_at)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.name)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.email)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.phone)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.terms ? 'Aceptados' : 'No aceptados')+" ")]),_c('vs-td',[_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"icon":"redeem","color":"primary","type":"gradient"},on:{"click":function($event){return _vm.detailOrder(tr)}}},[_vm._v(" Ver compras ")])],1)])],1)})}}])},[_c('template',{slot:"header"},[_c('h4',[_vm._v("Lista de clientes")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" Fecha de registro ")]),_c('vs-th',[_vm._v(" Nombre ")]),_c('vs-th',[_vm._v(" Correo ")]),_c('vs-th',[_vm._v(" Teléfono ")]),_c('vs-th',[_vm._v(" Terminos ")]),_c('vs-th',[_vm._v(" Detalle ")])],1)],2),_c('div',[_c('vs-pagination',{staticClass:"mt-2",attrs:{"total":_vm.total_pages},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }