<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Clientes',
              active: true
            }
          ]"
        ></vs-breadcrumb>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col d-flex justify-content-between flex-wrap">
        <date-picker @change="getDaterange()" style="width: 220px" v-model="daterange" range></date-picker>
        <div>
          <vs-input style="width: 400px" @keyup.enter="getClients()" placeholder="Buscar por nombre/correo (Presione ENTER)" v-model="search" class="inputx"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table class="mt-2" stripe :data="clients">
            <template slot="header">
              <h4>Lista de clientes</h4>
            </template>
            <template slot="thead">
              <vs-th>
                Fecha de registro
              </vs-th>
              <vs-th>
                Nombre
              </vs-th>
              <vs-th>
                Correo
              </vs-th>
              <vs-th>
                Teléfono
              </vs-th>
              <vs-th>
                Terminos
              </vs-th>
              <vs-th>
                Detalle
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td>
                  {{ tr.created_at }}
                </vs-td>

                <vs-td>
                  {{ tr.name }}
                </vs-td>

                <vs-td>
                  {{ tr.email }}
                </vs-td>

                <vs-td>
                  {{ tr.phone }}
                </vs-td>

                <vs-td>
                  {{ tr.terms ? 'Aceptados' : 'No aceptados' }}
                </vs-td>

                <vs-td>
                  <div class="d-flex">
                    <vs-button icon="redeem" @click="detailOrder(tr)" color="primary" type="gradient">
                      Ver compras
                    </vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination class="mt-2" :total="total_pages" v-model="current_page"></vs-pagination>
          </div>
        </vs-card>
      </div>
    </div>

  </div>
</template>

<script>
import clientService from '@/services/client.service'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  components: { DatePicker },
	data: () => {
		return {
      daterange: [],
			search: '',
			clients: [],
			page: 1,
      total_pages: 1,
      current_page: 1
		}
	},
	watch: {
		current_page(newVal) {
			this.getClients(newVal)
		},
		search(newVal) {
			if (newVal == '') {
				this.getUsers()
			}
		}
	},
	methods: {
    getDaterange() {
      if (this.daterange[0] && this.daterange[1]) {
        this.search = ''
      } else {
        this.daterange = []
      }
      this.getClients()
    },
		async getClients(page = 1) {
			this.$vs.loading({
        type: 'point'
      })
			const { data: res } = await clientService.getClients(page, this.search, this.daterange)
			if (res.success) {
				this.clients = res.data.data
				this.page = res.data.current_page
				this.total_pages = res.data.last_page
			} else {
				this.error = true
			}
			this.$vs.loading.close()
		}
	},
	created() {
		this.getClients()
	}
}
</script>
