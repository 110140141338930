import api from '../api'

class ClientService {
	static getClients(page, search, daterange) {
		return api({
			method: 'get',
			params: { page: page, search, daterange },
			url: 'users',
		})
	}
}

export default ClientService
